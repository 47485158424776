import './slide_menu.scss';

import Toggle_Height from 'classes/Toggle_Height';

let close_on_outside_click = true;
let disable_body_scroll_on_open = false;
let show_overlay_on_open = true;

let transition_duration = 600; // ms

let container;
let header;
let overlay;
let scrollbar_width;
let is_open = false;

export default function slide_menu_init(){

    container = document.querySelector('.ff_slide_menu');
    header = document.querySelector('.elementor-location-header');
    scrollbar_width = get_scrollbar_width();
    
    document.querySelectorAll('.slide_menu_open').forEach(item=>{
        item.addEventListener('click', open);
    });

    document.querySelectorAll('.slide_menu_close').forEach(item=>{
        item.addEventListener('click', close);
    });

    overlay_init();
    sub_menu_init();

    container.style.transform = 'translate3d(100%, 0, 0)';
    container.style.display = '';
    container.style.transition = 'none';

    setTimeout(()=>{
        container.style.transition = 'transform '+ transition_duration + 'ms cubic-bezier(0.39, 0.575, 0.565, 1)';
        // open();
    }, 10);
}

function on_open(){
    close_handler();
    disable_scroll();
    overlay_show();
}

function on_close(){
    close_handler_clear();
    overlay_hide();
}

function on_close_complete(){
    disable_scroll_clear();
}

function open(){

    if( is_open ) return;
    is_open = true;

    document.body.classList.add('slide_menu_is_open');
    container.style.transform = 'translate3d(0, 0, 0)';
    
    on_open();
}

function close(){

    if( !is_open ) return;
    is_open = false;

    document.body.classList.remove('slide_menu_is_open');
    container.style.transform = 'translate3d(100%, 0, 0)';

    on_close();

    setTimeout(()=>{
        on_close_complete();
    }, transition_duration);
}

function sub_menu_init(){
    container.querySelectorAll('.menu-item-has-children').forEach(container=>{
        
        let trigger = container.querySelector('a');
        let target = container.querySelector('.sub-menu');

        // hide initially
        target.style.display = 'none';
        
        new Toggle_Height({
            trigger,
            target,
        });
    });
}

function close_handler(){
    if( !close_on_outside_click ) return;
    setTimeout(()=>{
        document.body.addEventListener('click', outside_click_handler);
    }, 50);

    document.body.addEventListener('keydown', escape_press_close);
}

function close_handler_clear(){
    if( !close_on_outside_click ) return;
    document.body.removeEventListener('click', outside_click_handler);
    document.body.removeEventListener('keydown', escape_press_close);
}

function outside_click_handler(e){
    let outside_click = !e.target.closest('.ff_slide_menu');
    if( outside_click ) {
        close();
    }
}

function escape_press_close(e){
    if( e.key == 'Escape' ) close();
}

function disable_scroll(){
    if( !disable_body_scroll_on_open ) return;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = scrollbar_width + 'px';
    
    if( header && header.style.position == 'fixed' ) {
        header.style.paddingRight = scrollbar_width + 'px';
    }
}

function disable_scroll_clear(){
    if( !disable_body_scroll_on_open ) return;
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';

    if( header ) {
        header.style.paddingRight = '';
    }
}

function overlay_init(){
    if( !show_overlay_on_open ) return;
    overlay = document.createElement('div');
    overlay.classList.add('ff_slide_menu_overlay');
    overlay.style.opacity = 0;
    document.body.append(overlay);
}

function overlay_show(){
    if( !show_overlay_on_open ) return;
    overlay.style.opacity = 1;
}

function overlay_hide(){
    if( !show_overlay_on_open ) return;
    overlay.style.opacity = 0;
}

function get_scrollbar_width() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}