import form_style_2_init from './form_styles/form_style_2';
import Dropdown from 'classes/Dropdown';

export default function forms_init(){
    gravityforms_init();
    raw_select_styling();
}

function gravityforms_init(){
    if( typeof jQuery === 'undefined' ) return;
    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        const form = document.getElementById('gform_'+ form_id);
        if( !form ) return;
        // console.log('gform_post_render', form_id)
        dropdown_children_init(form);
        form_style_2_init(form);

        console.log(current_page);
        if(form_id == 8){
            star_review_radio();
        }
    });
}

function star_review_radio(){
    var field = 'choice_8_7_';
    var radio_box = jQuery('.custom-star-rating .ginput_container');
    radio_box.append(`<div class="stars">
        <label class="icon-star" for="${field}0"></label>
        <label class="icon-star" for="${field}1"></label>
        <label class="icon-star" for="${field}2"></label>
        <label class="icon-star" for="${field}3"></label>
        <label class="icon-star" for="${field}4"></label>
    </div>`); 

    radio_box.find('.gfield_radio').hide();

    var icon_star = radio_box.find('.stars label');
    icon_star.on('click',function(){
        var id = jQuery(this).attr('for');
        var num = id.split("_")[3];
        
        icon_star.removeClass('active');
        console.log(num);
        setTimeout(() => { 
            icon_star.each(function(i){
                if(i > num) return false;
                jQuery(this).addClass('active');  
            });
            /*for(var i = 0 ; i > num ; i++){
                icon_star[i].addClass('active'); 
            }*/
        }, 20);
        
    }); 


}

function dropdown_children_init(container){
    if( !container ) return;
    container.querySelectorAll('select').forEach(dropdown=>{
        new Dropdown(dropdown, {
            animation_open: 'fade-in-bottom-short 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both',
            // trigger gravityforms conditions
            on_select: ()=>{
                jQuery(dropdown).trigger('change');
            }, 
        });
    })
}

function raw_select_styling(){
    document.querySelectorAll('.select_style').forEach(dropdown=>{
        new Dropdown(dropdown, {
            animation_open: 'fade-in-bottom-short 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both',
        });
    })
}